import styled from "styled-components";
import landing from "./Media/Landing.jpg";

const pageMeta = {
  title: "Nasser Allabwani",
  keywords: [
    "University of Toronto",
    "Computer Engineering",
    "Full Stack",
    "Artificial Intelligence",
  ],
  description:
    "A Very Optimistic Student Engineer. University of Toronto, Computer Engineering. Abdulnaser (Nasser) Allabwani",
};

const LandingPage = styled.header`
  width: 100%;
  height: 100vh;
  background-image: url(${landing});
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
`;

const About = styled.section`
  width: 100%;
  height: 100vh;
  background-color: green;
`;
export { pageMeta, About, LandingPage };
