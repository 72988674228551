//import { useEffect } from "react";
import "./BouncingBall.scss";

function BouncingBall() {
  //   useEffect(() => {

  // window.addEventListener("scroll", function () {
  //   const about = document.getElementsByClassName("bouncingWrapper");
  //   const scrollPercent = (about.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

  //   console.log(bouncingBall.speed);
  // });
  //   })

  return (
    <div
      class="bouncingWrapper"
      style={{
        position: "absolute",
        width: "50%",
        height: "100%",
        left: "25vw",
        "padding-left": "0",
        "margin-left": "0",
      }}
    >
      <div class="fall">
        <div class="walls">
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
          <div class="wall">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="ceil"></div>
          </div>
        </div>

        <div class="ballArm">
          <div class="ball"></div>
        </div>
      </div>
    </div>
  );
}

export default BouncingBall;
