import styled from "styled-components";
import GESTConferenceBackground from "../Media/GESTConferenceBackgroundCropped.jpg";
import GESTConferenceCard from "../Media/GESTConferenceCard.jpg";
import ReactPlayer from "react-player/youtube";
import GESTConferenceFooter from "../Media/GESTConferenceFooter.png";

const Background = styled.header`
  position: relative;
  width: 100%;
  height: 130vw;
  background-color: black;
  overflow: hidden;
`;

const GestBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 70vw;
  background-image: linear-gradient(
      to bottom,
      rgba(136, 136, 136, 0),
      rgba(37, 37, 37, 0)
    ),
    url(${GESTConferenceBackground});
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
`;

const Banner = styled.div`
 className: "banner-text"
  display: inline-block;
  position: absolute;
  top: 13%;
  left: 7%;
`;
const Title = styled.h1`
  margin: 0;
  padding: 0;
  classname: "responsive-headline";
  font-size: 8vw;
  color: white;
  font-family: Poppins-Bold;
  letter-spacing: 0.02em;
  font-weight: 50;
  line-height: 1.05;
`;
const Description = styled.h1`
  classname: "responsive-description";
  font-size: 4vw;
  margin-top: 0vw;
  color: white;
  font-family: Poppins-Bold;
  font-weight: 200;
  letter-spacing: 0em;
`;

const CardA = styled.div`
  position: absolute;
  top: 13vw;
  right: 5vw;
  background-color: white;
  box-shadow: var(--shd, 1.5vw 1vw 0.5vw rgba(2, 30, 60, 0.5));
`;

const Caption = styled.h3`
  margin-top: 2vw;
  margin-bottom: 2vw;
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 1.2vw;
  text-align: center;
  color: rgba(48, 48, 48, 0.9);
  font-family: Poppins-Bold;
  letter-spacing: 0.01em;
  line-height: 1.5em;
  @media (max-width: 768px) {
    letter-spacing: -0.1em;
    font-size: 1vw;
    font-family: Poppins-Light;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
`;

const CardC = styled.div`
  position: absolute;
  top: 60vw;
  left: 5vw;
  width: 35vw;
  background-color: white;
  box-shadow: var(--shd, 1.5vw 1vw 0.5vw rgba(0, 0, 0, 0.5));
`;

const GESTConferenceCardWrapper = styled.div`
  position: relative;
  height: 14.5vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 255, 255, 0.01),
      rgba(94, 200, 230, 0.2)
    ),
    url(${GESTConferenceCard});
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
`;

const GESTRecordingWrapper = styled.div`
  position: absolute;
  top: 80vw;
  right: 10vw;
  width: 32vw;
  height: 35vw;
  margin: 3vw;
  margin-left: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GESTDemoBackground = styled.div`
  position: absolute;
  top: -2%;
  width: 99%;
  height: 54%;
  background-color: white;
`;

const GESTConferenceFooterWrapper = styled.div`
  position: absolute;
  bottom: 0vw;
  width: 100%;
  height: 50vw;
  background-image: linear-gradient(
      to bottom,
      rgba(136, 136, 136, 0),
      rgba(37, 37, 37, 0)
    ),
    url(${GESTConferenceFooter});
  background-position: center; /* Center the image */
  background-size: 100% 150%; /* Resize the background image to cover the entire container */
`;

function LandingPage() {
  return (
    <Background>
      <GestBackground />
      <Banner className="Banner">
        <Title className="Title">GEST</Title>
        <Description className="Description">in Accessibility</Description>
      </Banner>
      <CardA className="CardA" data-aos="zoom-in" data-aos-duration="800">
        <Caption className="Caption">
          At Accelerating Innovations <br />
          For Rare Diseases <br />
          conference, <br />I introduced GEST, <br />
          an alternative to <br />
          touchpad for patients <br /> with Tetraplegia <br />
          (paralysis of all <br /> four limbs)
        </Caption>
      </CardA>
      <GESTConferenceFooterWrapper className="GESTConferenceFooterWrapper" />
      <CardC className="CardC" data-aos="zoom-in" data-aos-duration="800">
        <Caption style={{ margin: "2vw 2vw 2vw 2vw" }}>
          Tech R&D for rare diseases is underfunded <br />
          due to the small market size, leaving pockets of underprivileged
          population with rare diseases struggling with outdated solutions.
        </Caption>
        <GESTConferenceCardWrapper className="GESTConferenceCardWrapper" />
      </CardC>
      <GESTRecordingWrapper
        className="GESTRecordingWrapper"
        data-aos="zoom-in"
        data-aos-duration="800"
      >
        <GESTDemoBackground className="GESTDemoBackground" />
        <ReactPlayer
          url="https://youtu.be/DaYT2NCZ-JI"
          light
          width="95%"
          height="50%"
          playsinline
          config={{
            youtube: {
              playerVars: { showinfo: 0, controls: 1, modestbranding: 1 },
            },
          }}
          style={{
            position: "relative",
            // "box-shadow": "var(--shd, 0.5vw -0.3vw 0.9vw rgba(0, 0, 0, 0.5))",
          }}
        />
      </GESTRecordingWrapper>
    </Background>
  );
}

export default LandingPage;
