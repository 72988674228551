import styled from "styled-components";
import FooterBackground from "../Media/FooterBackground.png";
import ResumeBlack from "../Media/ResumeBlack.svg";
import LinkedInMini from "../Media/LinkedInMini.svg";
import FacebookMini from "../Media/FacebookMini.svg";
import logo from "../logo.svg";
import "./Footer.css";

const AbdulnaserAllabwani =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/Abdulnaser+Allabwani.pdf";

const WeirdRobot =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/WeirdRobot2.mov";

const Background = styled.header`
  position: relative;
  height: 90vw;
  width: 100%;
  background-color: white;
  overflow: hidden;
`;

const Caption = styled.h3`
  margin-top: 1.5vw;
  font-size: 1.3vw;
  text-align: left;
  color: rgba(48, 48, 48, 0.85);
  font-family: Poppins-Thin;
  letter-spacing: 0.03em;
  line-height: 1.5em;

  @media (max-width: 768px) {
    letter-spacing: -0.15em;
    font-family: Poppins-Thin;
  }
`;

const ResumeButton = () => {
  const ResumeWrapper = styled.div`
    positon: relative;
    margin-top: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
  `;

  const ResumeIconWrapper = styled.div`
    position: relative;
    height: 5vw;
    width: 3.5vw;
    background-image: url(${ResumeBlack});
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <ResumeWrapper className="ResumeWrapper">
      <a href={AbdulnaserAllabwani} target="_blank" rel="noopener noreferrer">
        <ResumeIconWrapper className="ResumeIconWrapper" />
      </a>
      <a
        style={{ "text-decoration": "none" }}
        href={AbdulnaserAllabwani}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Caption
          style={{ textAlign: "middle", marginTop: "1vw" }}
          className="Caption"
        >
          Resume
        </Caption>
      </a>
    </ResumeWrapper>
  );
};

const FooterBackgroundWrapper = styled.div`
  position: absolute;
  bottom: -50vw;
  width: 130vw;
  height: 120vw;
  background-image: url(${FooterBackground});
  background-size: contain;
  background-repeat: no-repeat;
  ${"" /* background-position: right top; */}
  z-index: 3;
  ${"" /* transform: rotate(57deg); */}

  @media (max-width: 768px) {
    bottom: -43vw;
  }
`;

const WeirdRobotVideo = styled.div`
  position: absolute;
  left: 25vw;
  bottom: 0vw;
  width: 10vw;
  height: 13.6vw;
  overflow: hidden;
  z-index: 0;
`;

const FooterTitle = () => {
  const Footer = styled.div`
    position: absolute;
    bottom: 3vw;
    right: 13vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 4;

    @media (max-width: 768px) {
      bottom: 9vw;
      right: 6vw;
    }
  `;
  const Caption = styled.h3`
    font-size: 0.7vw;
    text-align: left;
    color: rgba(48, 48, 48, 0.85);
    font-family: Poppins-Thin;
    letter-spacing: 0.03em;
    line-height: 1.5em;
    @media (max-width: 768px) {
      letter-spacing: -0.15em;
      font-family: Poppins-Light;
    }
  `;

  const FooterTitleWrapper = styled.div`
    position: relative;
  `;

  const Social = styled.div`
    position: relative;
    top: -0.7vw;
    width: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `;

  const IconWrapper = styled.div`
    position: relative;
    width: 2vw;
    height: 2vw;
    margin: 0.6vw 2vw 0vw 2vw;
    background-image: url(${FacebookMini});
    background-size: contain;
    background-repeat: no-repeat;
  `;

  return (
    <Footer>
      <Social>
        <a
          href="https://www.facebook.com/abdalnasser.bwn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper />
        </a>
        <a
          href="http://linkedin.com/in/abdulnaser-allabwani/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper style={{ "background-image": `url(${LinkedInMini})` }} />
        </a>
      </Social>
      <FooterTitleWrapper>
        <Caption style={{ "margin-bottom": "-0.3vw" }}>
          Abdulnaser (Nasser) Allabwani
        </Caption>
        <Caption>
          Software Engineer - Amazon
          <br />
          BEng Computer Engineering - University of Toronto
          <br />
          Toronto, Canada
          <br />
          <br />
          labwani.com@gmail.com
        </Caption>
      </FooterTitleWrapper>
    </Footer>
  );
};

const Credit = () => {
  const CreditWrapper = styled.div`
    position: absolute;
    bottom: 1vw;
    right: 2vw;
    height: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 20vw;
    }
  `;
  return (
    <CreditWrapper>
      <Caption
        style={{
          "font-size": "0.7vw",
          "margin-bottom": "1vw",
          color: "rgba(48, 48, 48, 0.65)",
        }}
      >
        Site developed using
      </Caption>

      <img src={logo} className="App-logo" alt="logo" />
      <Caption
        style={{
          "font-size": "0.7vw",
          "margin-bottom": "1vw",
          color: "rgba(48, 48, 48, 0.65)",
        }}
      >
        by me
      </Caption>
    </CreditWrapper>
  );
};

function About() {
  return (
    <Background>
      <ResumeButton className="Resume" />
      <WeirdRobotVideo>
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          id="video"
          style={{ width: "100%" }}
        >
          <source src={WeirdRobot} type="video/mp4"></source>
        </video>
      </WeirdRobotVideo>
      <FooterTitle />
      <FooterBackgroundWrapper className="FooterBackgroundWrapper" />
      <Credit className="Credit" />
    </Background>
  );
}

export default About;
