import styled from "styled-components";
import UofTLogo from "../Media/UofTLogo.png";
import BouncingBall from "./BouncingBall";
import ReactPlayer from "react-player/youtube";

const Background = styled.header`
  position: relative;
  height: 250vw;
  width: 100vw;
  background-color: black;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 380vw;
  }
`;

const Banner = styled.div`
  position: relative;
  height: 30vw;
  top: 15vw;
  left: 7%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media (max-width: 768px) {
    height: 60vw;
  }
`;

const Title = styled.h2`
  font-size: 2.2vw;
  color: white;
  font-family: Poppins-Medium;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 30vw;
  top: 35vw;
`;

const TitleWrapper2 = styled.div`
  position: relative;
  width: 30vw;
  top: 50vw;
  left: 73vw;

  @media (max-width: 768px) {
    left: 63vw;
  }
`;

const UofT = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 20%;
  height: 40%;

  background-image: url(${UofTLogo});
  background-size: contain;
  background-repeat: no-repeat;
`;

const DemoWrapper = styled.div`
  position: relative;
  top: 0vw;
  width: 40vw;
  height: 30vw;
  margin: 3vw;
  margin-left: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 79vw;
    height: 60vw;
  }
`;

const DemoBackground = styled.div`
  position: absolute;
  top: 0;
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.205);
  border-radius: 0.25vw;
`;

function About() {
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  return (
    <Background>
      <BouncingBall />
      <Banner className="Banner" data-aos="fade">
        <UofT className="UofT" />

        <div>
          <Title className="Title">
            I hold a computer <br />
            engineering degree from <br />
            the University of Toronto
          </Title>
        </div>
      </Banner>
      <TitleWrapper2 data-aos="fade" className="TitleWrapper2">
        <Title className="Title">
          I Like To Identify <br />
          Boring Tasks <br />I Do Frequently
        </Title>
      </TitleWrapper2>
      <TitleWrapper
        data-aos="fade"
        className="TitleWrapper"
        style={{ top: "70vw", left: "7vw" }}
      >
        <Title className="Title2">
          And Develop Solutions
          <br /> For Them
        </Title>
      </TitleWrapper>

      <TitleWrapper2
        className="TitleWrapper2"
        data-aos="fade"
        style={{ top: "90vw" }}
      >
        <Title className="Title3">
          Then Evaluate <br />
          Outcomes
        </Title>
      </TitleWrapper2>

      <TitleWrapper
        data-aos="fade"
        className="TitleWrapper"
        style={{ top: "100vw", left: "7vw" }}
      >
        <Title className="Title4">
          And Repeat <br />
          The Cycle
        </Title>
      </TitleWrapper>

      <TitleWrapper
        className="TitleWrapper"
        data-aos="fade"
        style={{
          top: "125vw",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <DemoWrapper className="DemoWrapper">
          <DemoBackground>
            <Title
              className="Title3"
              style={
                isMobileDevice
                  ? { marginBottom: "5vw" }
                  : { marginBottom: "3vw" }
              }
            >
              Here's an example
            </Title>
            <ReactPlayer
              url="https://youtu.be/LPvc4JaQhSg"
              light
              width={isMobileDevice ? "75vw" : "30vw"}
              height={isMobileDevice ? "39vw" : "17vw"}
              playsinline
              config={{
                youtube: {
                  playerVars: { showinfo: 0, controls: 1, modestbranding: 1 },
                },
              }}
              style={{
                position: "relative",
                border: "solid 3px rgba(255, 255, 255, 0.205)",
                borderRadius: "0.25vw",
                boxShadow: "rgb(0 0 0 / 51%) 0vw -3vw 2vw -3vw",
              }}
            />
          </DemoBackground>
        </DemoWrapper>
      </TitleWrapper>
    </Background>
  );
}

export default About;
