import styled from "styled-components";
import AutoConnect from "../Media/vectors/AutoConnect";
import "./GitProjects.scss";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import CodeGram from "../Media/vectors/CodeGram";
import AutoBook from "../Media/vectors/AutoBook";
import GitHub from "../Media/vectors/GitHub";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowRight from "../Media/vectors/ArrowRight";
import ArrowLeft from "../Media/vectors/ArrowLeft";

const Background = styled.header`
  position: relative;
  height: 75vw;
  width: 100vw;
  background-color: black;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 80vw;
  }
`;

const Title = styled.h1`
  position: relative;
  font-size: 9vw;
  align-text: center;
  color: black;
  font-family: Poppins-ExtraBold;
  @media (max-width: 768px) {
    font-size: 8.2vw;
  }
`;

const MyWorkTitleWrapper = styled.div`
  height: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const MyWork2 = styled.div`
  positon: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5vw;
  width: 87vw;
  margin-right: 13vw;

  background-color: #f3f3f3;
  overflow: hidden;
`;

const WorkWrapper = styled.div`
  position: relative;
  height: 17%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Bar2 = styled.div`
  position: absolute;
  right: 0vw;
  width: 3.3vw;
  height: 5vw;
  overflow: hidden;
  z-index: 9;
  background-color: #f3f3f3;
`;

const Bar3 = styled.div`
  position: absolute;
  right: 5vw;
  width: 2vw;
  height: 5vw;
  overflow: hidden;
  z-index: 9;
  background-color: #f3f3f3;
`;

const Bar4 = styled.div`
  position: absolute;
  right: 7.5vw;
  width: 0.2vw;
  height: 5vw;
  overflow: hidden;
  z-index: 9;
  background-color: #f3f3f3;
`;

const Bar5 = styled.div`
  position: absolute;
  right: 9.4vw;
  width: 1vw;
  height: 5vw;
  overflow: hidden;
  z-index: 9;
  background-color: #f3f3f3;
`;

const Bar6 = styled.div`
  position: absolute;
  right: 12vw;
  width: 0.5vw;
  height: 5vw;
  overflow: hidden;
  z-index: 9;
  background-color: #f3f3f3;
`;

const MyWork = () => {
  return (
    <WorkWrapper className="WorkWrapper">
      <Bar2 data-aos="slide-left" data-aos-delay="600" />
      <Bar3 data-aos="slide-left" data-aos-delay="800" />
      <Bar4 data-aos="slide-left" data-aos-delay="1100" />
      <Bar5 data-aos="slide-left" data-aos-delay="1400" />
      <Bar6 data-aos="slide-left" data-aos-delay="1700" />
      <MyWork2 className="MyWork2">
        <MyWorkTitleWrapper className="MyWorkTitleWrapper">
          <Title className="Title" data-aos="slide-right">
            RECENT PROJECTS
          </Title>
        </MyWorkTitleWrapper>
      </MyWork2>
    </WorkWrapper>
  );
};

const GitButtonWrapper = styled.div`
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const idAnimations = {
  projRectCard: "dash 1s ease-in-out 1000ms forwards",
  iphone: "dash 10s ease-in-out 1000ms forwards",
  Subtitle: "dashReverse 3s ease-in-out 10ms forwards",
  Arrow: "dashReverse 3s ease-in-out 10ms forwards",
  MacBook: "dash 7s ease-in-out 1000ms forwards",
  GitIcon: "dashReverseGit 3s ease-in-out 10ms forwards",
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1.2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1.2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1.2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.2,
  },
};

const ButtonLeft = styled.div``;
const ButtonRight = styled.div``;
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  return (
    <div className="carousel-button-group">
      <ButtonLeft className="button-background" onClick={() => previous()}>
        <ArrowLeft />
      </ButtonLeft>
      <ButtonRight className="button-background" onClick={() => next()}>
        <ArrowRight />
      </ButtonRight>
    </div>
  );
};

function GitProjects() {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    Object.entries(idAnimations).forEach(([key, value]) => {
      let elements = document.getElementsByClassName(`${key}`);

      Object.values(elements).forEach((element) => {
        element.style.animation = inView ? value : "none";
      });
    });
  }, [inView, entry]);

  return (
    <Background className="GitProjects">
      <MyWork className="MyWork" />
      <div className="carousel-container" ref={ref}>
        <Carousel
          className="carousel"
          responsive={responsive}
          itemClass="carousel-item"
          infinite={true}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          centerMode={true}
        >
          <AutoBook />
          <AutoConnect />
          <CodeGram />
          <AutoBook />
          <AutoConnect />
          <CodeGram />
          <AutoBook />
          <AutoConnect />
          <CodeGram />
        </Carousel>
      </div>
      <GitButtonWrapper className="GitButtonWrapper">
        <GitHub className="GitHub" />
      </GitButtonWrapper>
    </Background>
  );
}

export default GitProjects;
