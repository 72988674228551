import styled from "styled-components";

const Card = styled.div`
  position: relative;
  width: 2vw;
  height: 4vw;
`;

const ArrowLeft = () => {
  return (
    <Card>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 14 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="ArrowIcon"
          id="ArrowLeft"
          d="M13 47L1.70103 24.4021C1.26185 23.5237 1.28037 22.486 1.75062 21.6239L13 1"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </Card>
  );
};

export default ArrowLeft;
