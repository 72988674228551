import styled from "styled-components";
import PelicanLogo from "../Media/PelicanLogo.png";
import TravelPayouts from "../Media/TravelPayouts.png";
import OfficialAuthorities from "../Media/OfficialAuthorities.png";
import React from "react";

const PelicanBackgroundVideo =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/PelicanBackground.mov";

const Background = styled.header`
  position: relative;
  width: 100%;
  height: 135vw;
  background-color: black;
`;

const PelicanHeader = () => {
  const PelicanTitle = styled.span`
    position: relative;
    display: flex;
    height: 6vw;
    width: 20vw;
    font-size: 3vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(126, 131, 143, 0.9);
    color: white;
    font-family: Poppins-Bold;
    font-weight: 550;
    letter-spacing: 0.02em;
    line-height: 1.5em;
  `;

  const PelicanHeaderWrapper = styled.div`
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: top;
  `;
  const PelicanTitleWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 20vw 20vw;
    width: 35vw;
    background: transparent;
  `;

  const Incorporated = styled.p`
    position: absolute;
    top: -0.3vw;
    right: 0;
    font-size: 0.9vw;
    text-align: left;
    color: white;
    font-family: Poppins-ExtraLight;
    letter-spacing: 0.01em;
    line-height: 1.3em;
  `;

  return (
    <PelicanHeaderWrapper className="PelicanHeaderWrapper">
      <PelicanTitleWrapper className="PelicanTitleWrapper">
        <PelicanTitle
          className="PelicanTitle1"
          data-aos="flip-right"
          data-aos-duration="800"
        >
          Founded
        </PelicanTitle>
        <PelicanTitle
          className="PelicanTitle2"
          style={{ background: "transparent" }}
        />
        <PelicanTitle
          style={{ background: "transparent", color: "#F7DF05" }}
          className="PelicanTitle2"
        >
          Pelican
        </PelicanTitle>
        <PelicanTitle
          data-aos="flip-left"
          data-aos-duration="800"
          className="PelicanTitle3"
          style={{
            "background-color": "white",
            color: "#00255E",
            width: "23vw",
          }}
        >
          Community
        </PelicanTitle>
        <PelicanTitle
          className="PelicanTitle4"
          style={{ background: "transparent" }}
        />
        <PelicanTitle
          className="PelicanTitle5"
          style={{ background: "transparent", right: "-3vw" }}
        >
          <Incorporated>
            The Pelican Community Inc. <br />
            Corp #. 11766724
          </Incorporated>
        </PelicanTitle>
      </PelicanTitleWrapper>
    </PelicanHeaderWrapper>
  );
};

const PelicanBackground = () => {
  const VideoBackground = styled.div`
    position: absolute;
    top: 5vw;
    width: 100vw;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <VideoBackground>
      <video
        playsInline
        autoPlay
        loop
        muted
        preload="auto"
        id="video"
        style={{ width: "85%" }}
      >
        <source src={PelicanBackgroundVideo} type="video/mp4"></source>
      </video>
    </VideoBackground>
  );
};

const Title = styled.h1`
  classname: "responsive-headline";
  font-size: 3vw;
  color: white;
  font-family: Poppins-Bold;
  letter-spacing: 0.02em;
  font-weight: 50;
  line-height: 1.05;
`;

const PelicanWebsite = () => {
  const Banner = styled.div`
    position: relative;
    top: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
  const PelicanLogoWrapper = styled.div`
    position: relative;
    width: 11vw;
    height: 11vw;
    background-image: url(${PelicanLogo});
    background-size: contain;
    background-repeat: no-repeat;
  `;
  return (
    <Banner>
      <PelicanLogoWrapper />
      <a
        href="https://www.PelicanCommunity.com"
        target="_blank"
        rel="noopener noreferrer"
        className="Banner"
        style={{ "text-decoration": "none" }}
      >
        <Title className="Title">www.PelicanCommunity.com</Title>
      </a>
    </Banner>
  );
};

const Description = styled.h1`
  position: relative;
  top: 32vw;
  classname: "responsive-description";
  font-size: 2vw;
  margin-top: 0vw;
  text-align: center;
  color: white;
  font-family: Poppins-Medium;
  font-weight: 200;
  letter-spacing: 0.01em;
  line-height: 1.5em;
`;

const Caption = styled.h3`
  margin-top: 2vw;
  margin-bottom: 2vw;
  font-size: 2vw;
  text-align: center;
  color: white;
  font-family: Poppins-Thin;
  letter-spacing: 0.03em;
  line-height: 1.5em;
  @media (max-width: 768px) {
    letter-spacing: -0.1em;
    font-size: 2vw;
    font-family: Poppins-ExtraLight;
  }
`;

const Grid = () => {
  const GridWrapper = styled.div`
    position: relative;
    top: 30vw;
    height: 70vw;
    margin-top: 10vw;
  `;

  const FlexBox = (props) => {
    const FlexBoxWrapper = styled.div`
      position: relative;
      display: flex;
      height: 12vw;
      margin-bottom: 1.6vw;
      flex-direction: row;
      align-items: center;
      background: transparent;
      justify-content: space-between;
    `;

    const Card = styled.div`
      position: relative;
      background-color: rgba(1, 31, 78, 1);
      display: flex;
      flex-direction: row;
      align-items: center;
    `;

    return (
      <FlexBoxWrapper>
        <Card
          className="Card1"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="800"
          style={{
            width: props.CardStyles[1].width,
            height: "100%",
            "background-color": `${props.CardStyles[1].color}`,
            "justify-content": "center",
          }}
        >
          {props.CardStyles[1].content ? props.CardStyles[1].content : null}
        </Card>
        <Card
          className="Card2"
          data-aos="zoom-in"
          data-aos-delay="1200"
          data-aos-duration="1000"
          style={{
            width: props.CardStyles[2].width,
            height: "100%",
            "background-color": `${props.CardStyles[2].color}`,
            "justify-content": "center",
          }}
        >
          {props.CardStyles[2].content ? props.CardStyles[2].content : null}
        </Card>
        <Card
          className="Card3"
          data-aos="zoom-in"
          data-aos-duration="800"
          style={{
            width: props.CardStyles[3].width,
            height: "100%",
            "background-color": `${props.CardStyles[3].color}`,
            "justify-content": "center",
          }}
        >
          {props.CardStyles[3].content ? props.CardStyles[3].content : null}
        </Card>
        <Card
          className="Card4"
          data-aos="zoom-in"
          data-aos-delay="800"
          data-aos-duration="800"
          style={{
            width: props.CardStyles[4].width,
            height: "100%",
            "background-color": `${props.CardStyles[4].color}`,
            "justify-content": "center",
          }}
        >
          {props.CardStyles[4].content ? props.CardStyles[4].content : null}
        </Card>
      </FlexBoxWrapper>
    );
  };
  return (
    <GridWrapper>
      <FlexBox
        className="FlexBox1"
        CardStyles={{
          1: {
            color: "rgba(1, 31, 78, 1)",
            width: "35vw",
            content: (
              <Caption style={{ position: "relative", right: "-2vw" }}>
                Priority Air Courier <br />
                Shipping for $15/lb
              </Caption>
            ),
          },
          2: { color: "#F7DF05", width: "25vw", content: null },
          3: { color: "white", width: "10vw", content: null },
          4: {
            color: "rgba(126, 131, 143, 0.9)",
            width: "25vw",
            content: (
              <Caption
                style={{
                  position: "relative",
                  left: "-1vw",
                  "text-align": "left",
                }}
              >
                5-7 Days <br /> Delivery Time
              </Caption>
            ),
          },
        }}
      />
      <FlexBox
        className="FlexBox2"
        CardStyles={{
          1: {
            color: "white",
            width: "30vw",
            content: (
              <img
                src={TravelPayouts}
                style={{ height: "60%", width: "70%" }}
                alt=""
              />
            ),
          },
          2: {
            color: "rgba(126, 131, 143, 0.9)",
            width: "34vw",
            content: (
              <Caption
                style={{
                  "margin-left": "1vw",
                  "margin-right": "1vw",
                  "text-align": "middle",
                  "font-size": "1.3vw",
                }}
              >
                Allowing airlines to tap into the global parcel delivery market
                while Pelican handles the logistics management{" "}
              </Caption>
            ),
          },
          3: {
            color: "#F7DF05",
            width: "18vw",
            content: (
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="35.516 35.514 128.968 128.972"
                viewBox="35.516 35.514 128.968 128.972"
                height="75%"
                xmlns="http://www.w3.org/2000/svg"
                data-type="tint"
                role="img"
              >
                <g>
                  <path
                    d="M100.002 35.514c-35.559 0-64.486 28.926-64.486 64.486s28.926 64.486 64.486 64.486c35.555 0 64.482-28.926 64.482-64.486s-28.927-64.486-64.482-64.486zm-32.039 110.94v-7.07a3.753 3.753 0 0 1 3.747-3.747h56.581a3.754 3.754 0 0 1 3.751 3.747v7.069c-9.112 6.306-20.144 10.023-32.039 10.023-11.896 0-22.928-3.717-32.04-10.022zm14.721-58.876c-2.21 0-4.005-1.795-4.005-4.005s1.795-4.005 4.005-4.005h34.632c2.21 0 4.005 1.795 4.005 4.005s-1.795 4.005-4.005 4.005H82.684zm25.328 8.01v32.039h-16.02V95.588h16.02zm32.039 44.178v-.381c0-6.485-5.276-11.757-11.761-11.757h-12.269v-32.04h1.295c6.625 0 12.015-5.389 12.015-12.015 0-6.625-5.389-12.015-12.015-12.015H82.684c-6.625 0-12.015 5.389-12.015 12.015 0 6.625 5.389 12.015 12.015 12.015h1.298v32.039H71.709c-6.485 0-11.757 5.272-11.757 11.757v.384C49.809 129.553 43.526 115.499 43.526 100c0-31.14 25.336-56.476 56.476-56.476S156.474 68.86 156.474 100c0 15.498-6.281 29.551-16.423 39.766z"
                    fill="#FAFAFA"
                  ></path>
                </g>
              </svg>
            ),
          },
          4: {
            color: "rgba(1, 31, 78, 1)",
            width: "13vw",
            content: null,
          },
        }}
      />
      <FlexBox
        className="FlexBox3"
        CardStyles={{
          1: {
            color: "rgba(126, 131, 143, 0.9)",
            width: "20vw",
            content: null,
          },
          2: {
            color: "#F7DF05",
            width: "13vw",
            content: (
              <Caption
                style={{
                  "text-align": "right",
                }}
              >
                Verified
                <br />
                By
              </Caption>
            ),
          },
          3: {
            color: "rgba(1, 31, 78, 1)",
            width: "36vw",
            content: (
              <img
                src={OfficialAuthorities}
                style={{
                  height: "70%",
                  position: "relative",
                  bottom: "-0.5vw",
                }}
                alt=""
              />
            ),
          },
          4: {
            color: "rgba(126, 131, 143, 0.9)",
            width: "26vw",
            content: null,
          },
        }}
      />
    </GridWrapper>
  );
};

function Pelican() {
  return (
    <Background>
      <PelicanHeader />
      <PelicanBackground />
      <PelicanWebsite />
      <Description>
        Pelican allows the daily 22 million passengers to purchase flight
        tickets at <br />
        below market rates by delivering priority ecommerce orders between
        international airports
      </Description>
      <Grid className="Grid" />
    </Background>
  );
}

export default Pelican;
