import styled from "styled-components";
import GESTTitleBackground from "../Media/GESTTitleBackground.png";
import GestLogo from "../Media/GestLogo.png";
import Muse from "../Media/Muse.png";
import ReactPlayer from "react-player/youtube";
import Arrow from "../Media/Arrow.svg";

const EMGReversed =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/EMGReversed.mov";
const ANN =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/ANN.mp4";

const Background = styled.header`
  position: relative;
  width: 100%;
  height: 135vw;
  background-color: black;
  overflow: hidden;
`;

const Banner = styled.div`
  position: relative;
  top: 15vw;
  left: 7vw;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const GESTTitleBackgroundWrapper = styled.div`
  position: relative;
  left: 0px;
  top: -2vw;
  width: 75%;
  height: 50vw;
  background-image: url(${GESTTitleBackground});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  filter: saturate(150%);
`;

const GESTTitle = styled.h1`
  font-size: 4vw;
  color: white;
  font-family: Poppins-Medium;
  letter-spacing: 0.02em;
`;

const GESTDescription = styled.h3`
  margin-top: -2.2vw;
  font-size: 2vw;
  color: white;
  font-family: Poppins-Medium;
  letter-spacing: 0em;
`;

const GESTLogo = styled.div`
  width: 12vw;
  height: 12vw;

  background-image: url(${GestLogo});
  background-size: contain;
  background-repeat: no-repeat;
`;

const GESTWrapper = styled.div`
  position: relative;
  top: -15vw;
  width: 100%;
  height: 120vw;
`;

const GESTSubTitle = styled.h3`
  font-size: 2.2vw;
  color: white;
  font-family: Poppins-Medium;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  @media (max-width: 768px) {
    font-family: Poppins-Medium;
  }
`;

const DescLogoWrapper = styled.div`
  position: relative;
  top: 6vw;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;
const GESTSubTitle2 = styled.h3`
  position: relative;
  font-size: 2.2vw;
  color: white;
  font-family: Poppins-Medium;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  @media (max-width: 768px) {
    font-family: Poppins-Medium;
  }
`;

const MuseWrapper = styled.div`
  position: relative;
  width: 30vw;
  height: 40vw;

  background-image: url(${Muse});
  background-size: contain;
  background-repeat: no-repeat;
`;

const MuseAndDescWrapper = styled.div`
  position: relative;
  top: 11vw;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;
const RubberElectrodes = styled.h3`
  position: absolute;
  top: 17vw;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 1vw;
  color: #fff;
  font-family: Poppins-Medium;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  @media (max-width: 768px) {
    font-family: Poppins-Light;
  }
`;

const FlexBoxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EMGWrapper = styled.div`
  position: relative;
  width: 20vw;
  height: 20vw;
  margin: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Caption = styled.h3`
  font-size: 1.2vw;
  text-align: center;
  color: white;
  font-family: Poppins-Medium;
  @media (max-width: 768px) {
    font-family: Poppins-Medium;
  }
`;

const ANNWrapper = styled.div`
  position: relative;
  width: 20vw;
  height: 20vw;
  margin: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GESTDemoWrapper = styled.div`
  position: relative;
  top: 1vw;
  width: 20vw;
  height: 20vw;
  margin: 3vw;
  margin-left: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GESTDemoBackground = styled.div`
  position: absolute;
  top: -1.5vw;
  left: 3vw;
  width: 90%;
  height: 50%;
  background-color: white;
`;

const ArrowWrapper = styled.div`
  position: relative;
  background-image: url(${Arrow});
  margin: 0vw 2vw 7vw 2vw;
  width: 2vw;
  height: 2vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

function Gest() {
  return (
    <Background>
      <Banner className="Banner">
        <div>
          <GESTTitle className="GESTTitle">
            Developed <span style={{ color: "#37ff63" }}>GEST</span> Technology
          </GESTTitle>
          <GESTDescription className="GESTDescription">
            An AI-powered earbud and I/O device
          </GESTDescription>
        </div>
      </Banner>
      <GESTTitleBackgroundWrapper
        className="GESTTitleBackgroundWrapper"
        data-aos="fade-up-right"
      />
      <GESTWrapper className="GESTWrapper">
        <DescLogoWrapper className="GESTDescLogoWrapper" data-aos="fade-up">
          <GESTSubTitle className="GESTSubTitle">
            Gest allows the user to control computers
            <br />
            and various electronics directly from their
            <br />
            head and without touching any buttons.
          </GESTSubTitle>
          <GESTLogo className="GESTLogo" />
        </DescLogoWrapper>
        <MuseAndDescWrapper data-aos="fade-up" className="MuseAndDescWrapper">
          <MuseWrapper className="MuseWrapper">
            <RubberElectrodes className="RubberElectrodes">
              Behind the ear
              <br />
              rubber electrodes
            </RubberElectrodes>
          </MuseWrapper>
          <GESTSubTitle2 className="GESTSubTitle2">
            Using deep learning, Gest classifies
            <br />
            the bio-electricity generated from
            <br />
            the movement of the user's facial
            <br />
            muscles and maps them into distinct
            <br />
            input actions (mouse clicks)
          </GESTSubTitle2>
        </MuseAndDescWrapper>
        <FlexBoxContainer class="flexbox-container" data-aos="fade-up">
          <EMGWrapper className="EMGWrapper">
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              id="video"
              style={{
                width: "60%",
                "margin-bottom": "1vw",
                filter: "contrast(1.2)",
                "border-radius": "100%",
                "object-fit": "cover",
              }}
            >
              <source src={EMGReversed} type="video/mp4"></source>
            </video>
            <Caption>
              EMG signal from
              <br />
              facial muscles
            </Caption>
          </EMGWrapper>
          <ArrowWrapper className="ArrowWrapper" />
          <ANNWrapper className="ANNWrapper">
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              id="video"
              style={{
                width: "60%",
                "margin-bottom": "1vw",
                filter: "contrast(1.2)",
                "object-fit": "center",
              }}
            >
              <source src={ANN} type="video/mp4"></source>
            </video>
            <Caption>
              Deep learning
              <br />
              classification of jaw
              <br />
              clenches as mouse clicks
            </Caption>
          </ANNWrapper>
          <ArrowWrapper className="ArrowWrapper" />
          <GESTDemoWrapper className="GESTDemoWrapper">
            <GESTDemoBackground />
            <ReactPlayer
              url="https://youtu.be/4K3cgfZvgaE"
              light
              width="92%"
              height="50%"
              playsinline
              config={{
                youtube: {
                  playerVars: { showinfo: 0, controls: 1, modestbranding: 1 },
                },
              }}
              style={{ position: "relative" }}
            />

            <Caption style={{ "margin-top": "3.5vw", "font-size": "2vw" }}>
              Super Powers!
            </Caption>
          </GESTDemoWrapper>
        </FlexBoxContainer>
      </GESTWrapper>
    </Background>
  );
}

export default Gest;
