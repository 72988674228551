import "./App.css";
import { pageMeta } from "./Styles";
import { Helmet } from "react-helmet";
import LandingPage from "./Components/LandingPage";
import About from "./Components/About";
import Gest from "./Components/Gest";
import GestConference from "./Components/GestConference";
import Pelican from "./Components/Pelican";
import OlderProjects from "./Components/OlderProjects";
import Footer from "./Components/Footer";
import GitProjects from "./Components/GitProjects";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="App">
      <Helmet>
        <title>{`${pageMeta.title}`}</title>
        <meta name="description" content={pageMeta.description} />
        <meta name="keywords" content={pageMeta.keywords.join(",")} />
      </Helmet>
      <LandingPage />
      <About id="About" />
      <GitProjects />
      {/* eslint-disable-next-line */}
      <a id="Gest">
        <Gest />
      </a>
      {/* eslint-disable-next-line */}
      <a id="GestConference">
        <GestConference />
      </a>
      <Pelican />
      <OlderProjects />
      <Footer />
    </div>
  );
}

export default App;
