import styled from "styled-components";
import Drone from "../Media/vectors/Drone";
import EagleEyeDrone from "../Media/EagleEyeDrone.png";
import OlderProjectsBackground from "../Media/OlderProjectsBackground-min.png";
import ArtiboniteImg from "../Media/ArtiboniteImg.jpg";
import Shop2GoImg from "../Media/Shop2GoImg.jpg";
import "./OlderProjects.scss";

const EagleRealtime =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/EagleRealtime.mp4";

const Background = styled.header`
  position: relative;
  height: 140vw;
  width: 100%;
  background-color: white;
  overflow: hidden;
`;

const HeaderBackground = styled.div`
  position: absolute;
  top: -5vw;
  right: -50vw;
  width: 130vw;
  height: 120vw;
  background-image: url(${OlderProjectsBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: 3;
  transform: rotate(57deg);
`;

const OlderProjectsTitle = () => {
  const Banner = styled.div`
    position: relative;
    top: 0vw;
    width: 100vw;
  `;

  const Title = styled.h1`
    position: relative;
    top: 25vw;
    left: 55vw;
    width: 25vw;
    font-size: 7vw;
    color: rgba(0, 0, 0, 0.75);
    font-family: Poppins-Thin;
    font-weight: 120;
    letter-spacing: 0.02em;
    line-spacing: 1.1em;
    z-index: 1;
    @media (max-width: 768px) {
      font-family: Poppins-ExtraLight;
    }
  `;

  const LocationWrapper = styled.div`
    position: absolute;
    top: 10vw;
    left: -18vw;
    width: 60vw;
    height: 60vw;
    z-index: 0;
  `;
  return (
    <Banner className="Banner">
      <LocationWrapper className="LocationWrapper">
        <Drone className="Drone" />
      </LocationWrapper>
      <div style={{ position: "relative" }}>
        <Title className="Title" data-aos="fade">
          Older Projects
        </Title>
      </div>
    </Banner>
  );
};

const Projects = () => {
  const GridWrapper = styled.div`
    position: relative;
    top: 52vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Grid = styled.div`
    position: relative;
    left: -3vw;
    width: 77vw;
    heigh: 90vw;
    background: transparent;
  `;

  const FlexBox = styled.div`
    position: relative;
    width: 100%;
    height: 29.5vw;
    margin-bottom: 1.5vw;
    background: transparent;
  `;

  const EagleEye = styled.div`
    position: absolute;
    right: 46.5vw;
    width: 20vw;
    height: 100%;
    background-color: rgba(48, 48, 48, 0.65);
    z-index: 3;
  `;

  const Eaglevideo = styled.div`
    position: absolute;
    right: 46.5vw;
    width: 20vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  `;

  const Bar2 = styled.div`
    position: absolute;
    right: 67.5vw;
    width: 3.3vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: #f3f3f3;
  `;

  const Bar3 = styled.div`
    position: absolute;
    right: 71.5vw;
    width: 2vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: #f3f3f3;
  `;

  const Bar4 = styled.div`
    position: absolute;
    right: 74vw;
    width: 0.2vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: #f3f3f3;
  `;

  const Bar5 = styled.div`
    position: absolute;
    right: 74.9vw;
    width: 1vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: #f3f3f3;
  `;

  const Bar6 = styled.div`
    position: absolute;
    right: 76.5vw;
    width: 0.5vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: #f3f3f3;
  `;

  const NasserDrone = styled.div`
    position: absolute;
    right: 0vw;
    width: 45vw;
    height: 100%;
  `;

  const ProjectB = styled.div`
    position: absolute;
    width: 37.7vw;
    height: 22vw;
  `;

  const ProjectBCover = styled.div`
    position: absolute;
    width: 37.7vw;
    height: 22vw;
    background-color: rgba(48, 48, 48, 0.35);
  `;

  const ProjTitle = styled.h1`
    classname: "responsive-ProjTitle";
    position: absolute;
    font-size: 4.5vw;
    margin: 3vw 1.8vw;
    color: white;
    font-family: Poppins-Thin;
    text-align: left;
    font-weight: 200;
    @media (max-width: 768px) {
      font-family: Poppins-ExtraLight;
    }
  `;

  const Caption = styled.h3`
    margin: 3vw 2vw 3vw 3vw;
    font-size: 1vw;
    text-align: left;
    color: rgba(48, 48, 48, 0.85);
    font-family: Poppins-Thin;
    letter-spacing: 0.03em;
    line-height: 1.5em;
    @media (max-width: 768px) {
      letter-spacing: -0.15em;
      font-size: 1vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
      font-family: Poppins-ExtraLight;
    }
  `;

  const Bar = styled.div`
    position: absolute;
    right: 0vw;
    bottom: 0vw;
    width: 45vw;
    height: 12.7vw;
    background-color: #f3f3f3;
  `;

  const ImageWrapper = styled.div`
    position: absolute;
    top: 0vw;
    width: 100%;
    height: 15.3vw;
    background-image: url(${EagleEyeDrone});
    background-size: cover;
    filter: grayscale(70%);
    background-repeat: no-repeat;
  `;

  const ProjBTitle = styled.h1`
    classname: "responsive-ProjTitle";
    position: absolute;
    font-size: 2.7vw;
    margin: 10% 10% 10% 10%;
    color: white;
    font-family: Poppins-Thin;
    text-align: left;
    font-weight: 200;
    @media (max-width: 768px) {
      font-family: Poppins-ExtraLight;
    }
  `;

  return (
    <GridWrapper className="GridWrapper">
      <Grid className="Grid">
        <FlexBox className="FlexBox1">
          <Bar2 data-aos="slide-left" data-aos-delay="200" />
          <Bar3 data-aos="slide-left" data-aos-delay="400" />
          <Bar4 data-aos="slide-left" data-aos-delay="700" />
          <Bar5 data-aos="slide-left" data-aos-delay="1000" />
          <Bar6 data-aos="slide-left" data-aos-delay="1300" />
          <Eaglevideo>
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              id="video"
              style={{ width: "100%" }}
            >
              <source src={EagleRealtime} type="video/mp4"></source>
            </video>
          </Eaglevideo>
          <EagleEye className="EagleEye">
            <ProjTitle>
              Eagle <br />
              Eye
            </ProjTitle>
          </EagleEye>
          <NasserDrone className="NasserDrone">
            <ImageWrapper className="ImageWrapper" />
            <Bar className="Bar">
              <Caption>
                A VR headset with electromyography electrodes that measures
                facial gestures (eyes orientation, jaw clench) and use them to
                control a drone.
                <br /> <br /> The drone camera sends a live video feed to the VR
                headset providing an immersive flying experience without the
                need for controllers{" "}
              </Caption>
            </Bar>
          </NasserDrone>
        </FlexBox>
        <FlexBox className="FlexBox2">
          <ProjectB
            style={{
              left: 0,
              "background-image": `url(${Shop2GoImg})`,
              "background-size": "cover",
              filter: "blur(1px)",
            }}
            className="ProjectB"
          />
          <ProjectBCover
            className="ProjectBCover"
            style={{
              left: 0,
            }}
          >
            <ProjBTitle>
              Shop2Go
              <br />
              Map Application
            </ProjBTitle>
            <Caption
              style={{
                position: "absolute",
                bottom: "18%",
                margin: "0 10% 0 10%",
                color: "white",
                font: "200",
              }}
            >
              Used web scraping and shortest path algorithms to match the items
              on the user’s shopping list with stores around their current
              location or along their navigation route.
            </Caption>
          </ProjectBCover>
          <ProjectB
            style={{
              right: 0,
              "background-image": `url(${ArtiboniteImg})`,
              "background-size": "cover",
              filter: "blur(1px)",
            }}
            className="ProjectB"
          />
          <ProjectBCover
            className="ProjectBCover"
            style={{
              right: 0,
            }}
          >
            <ProjBTitle>
              Project
              <br />
              Artibonite
            </ProjBTitle>
            <Caption
              style={{
                position: "absolute",
                bottom: "18%",
                margin: "0 10% 0 10%",
                color: "white",
              }}
            >
              Developed a cost-efficient solar-powered kiosk system to deliver
              Khan Academy to students in the Artibonite district, Haiti, where
              no electricity or internet access exists. 
            </Caption>
          </ProjectBCover>
        </FlexBox>
      </Grid>
    </GridWrapper>
  );
};

function About() {
  return (
    <Background>
      <HeaderBackground className="HeaderBackground" />
      <OlderProjectsTitle />
      <Projects />
    </Background>
  );
}

export default About;
