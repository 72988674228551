import styled from "styled-components";

const Card = styled.div`
  position: relative;
  width: 2vw;
  height: 4vw;
`;

const ArrowRight = () => {
  return (
    <Card>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 14 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="ArrowIcon"
          id="ArrowRight"
          d="M1 47L12.299 24.4021C12.7382 23.5237 12.7196 22.486 12.2494 21.6239L1 1"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </Card>
  );
};

export default ArrowRight;
