import styled from "styled-components";
import landing from "../Media/Landing.jpg";
import LinkedIn from "../Media/LinkedIn.svg";
import { useMediaPredicate } from "react-media-hook";
import ResumeWhite from "../Media/ResumeWhite.svg";

const Background = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(136, 136, 136, 0),
      rgba(37, 37, 37, 0)
    ),
    url(${landing});
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  overflow: hidden;
`;

const Banner = styled.div`
 className: "banner-text"
  display: inline-block;
  position: absolute;
  bottom: 10%;
  left: 7%;
`;
const Title = styled.h1`
  margin: 0;
  padding: 0;
  classname: "responsive-headline";
  font-size: 8vw;
  color: white;
  font-family: Poppins-Thin;
  letter-spacing: 0.02em;
  font-weight: 50;
  line-height: 1.05;
  @media (max-width: 768px) {
    font-size: 15vw;
    font-family: Poppins-Thin;
  }
`;
const Description = styled.h1`
  classname: "responsive-description";
  font-size: 2.8vw;
  color: white;
  font-family: Sacramento-Regular;
  font-weight: 200;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 6vw;
  }
`;

const LinkedInButton = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${LinkedIn});
  background-size: contain;
  background-repeat: no-repeat;
`;

const TitleWrapper = styled.div`
  position: absolute;
  bottom: 0vw;
  width: 100vw;
  height: 30vw;
  display: flex;
  flex-direction: row;
  alight-items: flex-start;
  @media (max-width: 768px) {
    bottom: 10vw;
  }
`;

const LinkedInButtonWrapper = () => {
  const LinkedInWrapper = styled.div`
    position: absolute;
    right: 5vw;
    bottom: 10vw;
    height: 7vw;
    width: 7vw;

    @media (max-width: 768px) {
      top: 7vw;
      right: 7vw;
      bottom: unset;
      height: 15vw;
      width: 15vw;
    }
  `;
  return (
    <LinkedInWrapper className="LinkedInWrapper">
      <a
        href="http://linkedin.com/in/abdulnaser-allabwani/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInButton className="LinkedInButton" />
      </a>
    </LinkedInWrapper>
  );
};

const AbdulnaserAllabwani =
  "https://portfoliobucketwrapper83514-dev.s3-us-west-2.amazonaws.com/Abdulnaser+Allabwani.pdf";

const ResumeButton = () => {
  const Caption = styled.h3`
    margin-top: 0.5vw;
    font-size: 0.8vw;
    text-align: center;
    color: white;
    font-family: Poppins-Thin;
    letter-spacing: 0.03em;
    line-height: 1.5em;

    @media (max-width: 768px) {
      letter-spacing: 0em;
      font-size: 2vw;
      margin-top: 0.8vw;
    }
  `;

  const ResumeWrapper = styled.div`
    position: absolute;
    right: 3.6vw;
    top: 4vw;
    height: 8vw;
    width: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
    @media (max-width: 768px) {
      top: 10vw;
      left: 7vw;
      height: 15vw;
      width: 15vw;
    }
  `;

  const ResumeIconWrapper = styled.div`
    position: relative;
    height: 7vw;
    width: 5vw;
    background-image: url(${ResumeWhite});
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
      height: 15vw;
      width: 10.5vw;
    }
  `;
  return (
    <ResumeWrapper className="ResumeWrapper">
      <a href={AbdulnaserAllabwani} target="_blank" rel="noopener noreferrer">
        <ResumeIconWrapper className="ResumeIconWrapper" />
      </a>
      <a
        style={{ "text-decoration": "none" }}
        href={AbdulnaserAllabwani}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Caption className="Caption">Resume</Caption>
      </a>
    </ResumeWrapper>
  );
};

function LandingPage() {
  const biggerThan768 = useMediaPredicate("(min-width: 768px)");
  return (
    <Background>
      {!biggerThan768 && <LinkedInButtonWrapper />}
      <ResumeButton />
      <TitleWrapper class="TitleWrapper">
        <Banner className="Banner" data-aos="fade-up">
          <Title className="Title">
            Nasser <br />
            Allabwani
          </Title>
          <Description className="Description">
            A Very Optimistic Software Engineer
          </Description>
        </Banner>
        {biggerThan768 && <LinkedInButtonWrapper />}
      </TitleWrapper>
    </Background>
  );
}

export default LandingPage;
